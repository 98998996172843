@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;600;700,800&display=swap');

body {
  background-color: #eee;
  font-family: 'Red Hat Display', sans-serif;
}

input:focus {
  outline: none !important;
}

.react-grid-item>.react-resizable-handle::after {
  border-color: #ccc;
}

@layer components {
  .input {
    @apply appearance-none rounded-lg w-full py-3 px-4 mb-2 bg-gray-100 text-gray-700 outline-none relative leading-tight;
  }

  .label {
    @apply font-semibold text-gray-600 mb-2 text-sm;
  }

  .link {
    @apply inline align-baseline text-blue-500 hover:text-blue-700;
  }

  .btn {
    @apply font-semibold uppercase py-3 px-7 rounded-xl text-center active:bg-gray-200;
  }

  .btn-primary {
    @apply bg-primary hover:opacity-95 transition text-white;
  }

  .btn-secondary {
    @apply bg-secondary text-primary hover:opacity-95 transition;
  }

  .btn-danger {
    @apply bg-red-600 hover:bg-red-700 transition text-white;
  }

}

.mini-scrollbar::-webkit-scrollbar {
  width: .3rem;
  border-radius: .25rem;
}

.mini-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.mini-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: .25rem;
}

.mini-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
}

.menu-item-desktop>.menu-item-desktop-options {
  display: none;
}

.menu-item-desktop:hover>.menu-item-desktop-options {
  display: block;
}

.mini-scrollbar::-webkit-scrollbar {
  width: .3rem;
  border-radius: .25rem;
}
.mini-scrollbar-x::-webkit-scrollbar {
  height: .3rem;
  border-radius: .25rem;
}

.mini-scrollbar::-webkit-scrollbar-track, .mini-scrollbar-x::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.mini-scrollbar::-webkit-scrollbar-thumb, .mini-scrollbar-x::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: .25rem;
}

.mini-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
}
